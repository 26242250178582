a {
	color: white;
	text-decoration: none;
}

body {
	background-image: url('images/spash-bg.jpg');
	background-position: center center;
	background-repeat:  no-repeat;
	background-attachment: fixed;
	background-size:  cover;
	position: relative;
	min-height: 100vh;
	@extend %clearfix;
	#overlay {
		background-image: url('images/overlay.png');
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	font-family: "rogue-sans-ext",sans-serif;
	font-style: normal;
	font-weight: 500;
	color: white;
}

.container {
	position: relative;
	text-align: center;
	max-width: 1200px;
	margin: 0pt auto;
}

img {
	width: 100%;
	max-width: 100%;
	height: auto;
	image-rendering: -moz-crisp-edges;         /* Firefox */
	image-rendering:   -o-crisp-edges;         /* Opera */
	image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
	image-rendering: crisp-edges;
	-ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
	display: block;
}

.top-stripe	{
	position: relative;
}

.contacts {
	@include grid-container;
	.contact {
		@include grid-column(12);
		@include grid-media($mini-grid){
	    	@include grid-column(6);
	  	}
		text-align: center;
		a {
			padding: 1rem;
			display: inline-block;
		}
		span {
			display: block;
		}
	}
	.container & {
		font-size: 2rem;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
}

.hero {
	@include grid-container;
	margin: 2rem 0 2rem;
	p {
		font-size: 2rem;
	}
}

.logo {
	@include grid-column(12);
	img {
		max-width: 600px;
		margin: 0pt auto;
	}
}
.intro {
	@include grid-column(12);
}

.ctas {
	@include grid-container;
	.cta {
		@include grid-column(12);
	  	@include grid-media($med-grid){
	    	@include grid-column(4);
	  	}
	  	margin-bottom: 20px;
	  	position: relative;
	  	h2 {
	  		font-size: 2rem;
	  		position: absolute;
	  		width: 100%;
	  		top: 50%;
	  		transform: translateY(-50%);
	  		margin: 1rem 0;
	  		text-align: center;
	  		background-image: url('images/overlay.png');
	  	}
	}
}
.coming-soon {
	padding: 0 20px 2rem;
	p {
		font-size: 1.5rem;
	}
}