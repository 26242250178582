/*!
Theme Name: Tubby
Theme URI: http://underscores.me/
Author: Neil Day
Author URI: http://underscores.me/
Description: Bourbon, Neat & Gulp
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: tubby
Tags:

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.

Single Malt is based on Underscores http://underscores.me/, (C) 2012-2016 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal http://necolas.github.com/normalize.css/
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Neat
# Variables
# Mixins
# Normalize
# Typography
# Elements
# Forms
# Navigation
	## Links
	## Menus
# Accessibility
# Alignments
# Clearings
# Widgets
# Content
    ## Posts and pages
	## Asides
	## Comments
# Infinite scroll
# Media
	## Captions
	## Galleries

/*--------------------------------------------------------------
# Bourbon & Neat
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Mixins
--------------------------------------------------------------*/
body {
  *zoom: 1;
}

body:before, body:after {
  content: " ";
  display: table;
}

body:after {
  clear: both;
}

/* .your-parent {
	@extend %clearfix
  }
} */
/* .your-list {
	@extend %unstyled-list
  }
} */
/* .texture {
  $texture-top-background: lighten(#ddf0ef, 1%);
  $texture-bottom-background: darken(#ddf0ef, 10%);
  height: 5em;
  width: 100%;

  .texture-normal {
    // gradient color 1, gradient color 2, gradient angle, texture-number, 0=inverted or 1=normal  
    @include texture(180deg, $texture-top-background, $texture-bottom-background, 67, 1); 
    float: left;
    height: 5em;
    width: 50%;
  }

  .texture-inverted {
    // gradient color 1, gradient color 2, gradient angle, texture-number, 0=inverted or 1=normal  
    @include texture(180deg, $texture-top-background, $texture-bottom-background, 67, 0); 
    float: left;
    height: 5em;
    width: 50%;
  }
} */
/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
a {
  color: white;
  text-decoration: none;
}

body {
  background-image: url("images/spash-bg.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  min-height: 100vh;
  font-family: "rogue-sans-ext",sans-serif;
  font-style: normal;
  font-weight: 500;
  color: white;
}

body #overlay {
  background-image: url("images/overlay.png");
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.container {
  position: relative;
  text-align: center;
  max-width: 1200px;
  margin: 0pt auto;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
  image-rendering: -moz-crisp-edges;
  /* Firefox */
  image-rendering: -o-crisp-edges;
  /* Opera */
  image-rendering: -webkit-optimize-contrast;
  /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  /* IE (non-standard property) */
  display: block;
}

.top-stripe {
  position: relative;
}

.contacts::after {
  clear: both;
  content: "";
  display: block;
}

.contacts .contact {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  text-align: center;
}

@media only screen and (min-width: 640px) {
  .contacts .contact {
    width: calc(50% - 30px);
    float: left;
    margin-left: 20px;
  }
}

.contacts .contact a {
  padding: 1rem;
  display: inline-block;
}

.contacts .contact span {
  display: block;
}

.container .contacts {
  font-size: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.hero {
  margin: 2rem 0 2rem;
}

.hero::after {
  clear: both;
  content: "";
  display: block;
}

.hero p {
  font-size: 2rem;
}

.logo {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
}

.logo img {
  max-width: 600px;
  margin: 0pt auto;
}

.intro {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
}

.ctas::after {
  clear: both;
  content: "";
  display: block;
}

.ctas .cta {
  width: calc(100% - 40px);
  float: left;
  margin-left: 20px;
  margin-bottom: 20px;
  position: relative;
}

@media only screen and (min-width: 800px) {
  .ctas .cta {
    width: calc(33.33333% - 26.66667px);
    float: left;
    margin-left: 20px;
  }
}

.ctas .cta h2 {
  font-size: 2rem;
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin: 1rem 0;
  text-align: center;
  background-image: url("images/overlay.png");
}

.coming-soon {
  padding: 0 20px 2rem;
}

.coming-soon p {
  font-size: 1.5rem;
}
