// Rem output with px fallback
@mixin font-size($sizeValue: 1) {
	font-size: ($sizeValue * 16) * 1px;
	font-size: $sizeValue * 1rem;
}

// Center block
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// A better clearfix
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}
// Usage
/* .your-parent {
	@extend %clearfix
  }
} */

// Unstyle the lists
%unstyled-list {
	list-style: none;
	margin: 0;
	padding: 0;
	li {
		padding: 0;
	}
}
// Usage
/* .your-list {
	@extend %unstyled-list
  }
} */

@mixin nest-parent($margin) {
  margin-left: -$margin !important;
  margin-right: -$margin !important;
}

